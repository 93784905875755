import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Icon from '@pc/components/Icon'
import Logger, * as elementId from '@pc/config/logger'
import BizTracker from '@pc/common/tracker/bizTracker'

import { helpers, withBasic } from '@common'
import { actionCreator } from '../../store'

import styles from './style.m.scss'
import NextIcon from '@pc/assets/img/openpay_ico_go@3x.png'
import loggerConfig from '../../logger.config'

const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class InstallmentDetails extends PureComponent {
  noEmpty = (val) => {
    return val !== '' && val !== undefined && val !== null
  }

  render() {
    const {
      translate,
      currencyCode,
      formatPrice,
      adTagContent,
      openModal,
      installmentList = [],
      curPeriod,
      payAmount,
    } = this.props

    const curSelectedItem = installmentList.find(
      (item) => curPeriod && Number(item.periods) === Number(curPeriod),
    )

    return (
      <div {...className('order_info font_lato_medium')}>
        <div {...className('order_downpayment')}>
          <div {...className('order_downpayment_text')}>
            {translate('支付金额')}
          </div>
          <div {...className('order_downpayment_detail')}>
            <div>
              <span {...className('downpayment_currency_code')}>
                {currencyCode}
              </span>
              <span {...className('downpayment_price')}>
                {formatPrice(payAmount)}
              </span>
            </div>
            {this.noEmpty(adTagContent) && (
              <div {...className('detail_right')}>{adTagContent}</div>
            )}
          </div>
        </div>
        <div {...className('installment_info')} onClick={openModal}>
          <div {...className('item_left')}>
            <div {...className('item_left_installment')}>
              {translate('{currencyCode} {price} * {num}期', {
                currencyCode: currencyCode,
                price: formatPrice(
                  curSelectedItem
                    ? curSelectedItem.monthlyInstallmentAmount
                    : '',
                ),
                num: curSelectedItem ? curSelectedItem.periods : '',
              })}
            </div>
            <div {...className('item_left_fee')}>
              {translate('每期利率{fee}%', {
                fee: curSelectedItem
                  ? (curSelectedItem.monthlyInterestRate * 100).toFixed(2)
                  : '',
              })}
            </div>
          </div>
          <div {...className('item_right')}>
            <Icon
              src={NextIcon}
              icontype="image"
              {...className('item_right_icon')}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  adTagContent: state.getIn(['login', 'adTagContent']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  installmentList: state.getIn(['login', 'installmentList']),
  payAmount: state.getIn(['login', 'payAmount']),
})

const mapDispatchToProps = (dispatch) => ({
  openModal() {
    // 分期方案弹窗曝光
    BizTracker.click({ cn: 1, sn: 390001 })
    BizTracker.expose({
      cn: 14,
      sn: 390001,
    })
    dispatch(actionCreator.setInstallmentVisible(true))
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.click_installment)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentDetails)
