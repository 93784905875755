import React, { PureComponent } from 'react'

import { helpers, withBasic } from '@common'
import Icon from '@pc/components/Icon'

import icon_step1 from '@pc/assets/img/step_1.png'
import icon_step2 from '@pc/assets/img/step_2.png'
import icon_step3 from '@pc/assets/img/step_3.png'
import icon_dots_line from '@pc/assets/img/dots_line.png'
import styles from './style.m.scss'

import icon_m_step1 from '@pc/assets/img/4@3x.png'
import icon_m_step2 from '@pc/assets/img/5@3x.png'
import icon_m_step3 from '@pc/assets/img/6@3x.png'

const className = helpers.classNames.react(styles)

@withBasic
export default class PaymentProcess extends PureComponent {
  render() {
    const { translate, hasDownload, size, type, platform } = this.props

    return (
      <div
        {...className('payment-process-area', {
          'small-icon': size === 'small',
        })}
      >
        {type === 'applylimits' ? (
          <div
            {...className('applylimits-process', {
              'pc-process-wrapper': platform === 'pc',
            })}
          >
            <div {...className('process-icon-block')}>
              <div>
                <Icon
                  {...className('process-icon')}
                  icontype="svg"
                  name="personalInfo"
                />
              </div>
              <p {...className('font-lato-medium')}>{translate('个人信息')}</p>
            </div>
            <i {...className('arrow-icon')}>
              <Icon icontype="svg" name="double-arrow" />
            </i>
            <div {...className('process-icon-block')}>
              <div>
                <Icon
                  {...className('process-icon')}
                  icontype="svg"
                  name="contact"
                />
              </div>
              <p {...className('font-lato-medium')}>{translate('联系信息')}</p>
            </div>
            <i {...className('arrow-icon')}>
              <Icon icontype="svg" name="double-arrow" />
            </i>
            <div {...className('process-icon-block')}>
              <div>
                <Icon
                  {...className('process-icon')}
                  icontype="svg"
                  name="occupation"
                />
              </div>
              <p {...className('font-lato-medium')}>{translate('身份信息')}</p>
            </div>
          </div>
        ) : (
          <div {...className('', { 'pc-payment-process': platform === 'pc' })}>
            {hasDownload
              ? [
                  <div {...className('process-icon-block')} key={'download'}>
                    <Icon
                      {...className('process-m-icon')}
                      icontype="svg"
                      name="downloadApp"
                    />
                    <p>{translate('下载Akulaku')}</p>
                  </div>,
                  <i {...className('arrow-icon')} key={'download-arrow'} />,
                ]
              : null}
            <div {...className('process-icon-block')}>
              <Icon
                {...className('process-m-icon')}
                icontype="image"
                src={icon_m_step1}
              />
              <p>{translate('登录Akulaku')}</p>
            </div>
            <i {...className('arrow-icon')} />
            <div {...className('process-icon-block')}>
              <Icon
                {...className('process-m-icon')}
                icontype="image"
                src={icon_m_step2}
              />
              <p>{translate('申请额度')}</p>
            </div>
            <i {...className('arrow-icon')} />
            <div {...className('process-icon-block')}>
              <Icon
                {...className('process-m-icon')}
                icontype="image"
                src={icon_m_step3}
              />
              <p>{translate('分期支付')}</p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

@withBasic
export class PaymentProcessPc extends PureComponent {
  render() {
    const { translate } = this.props

    return (
      <div {...className('payment-process-pc')}>
        <div {...className('process-icon-block')}>
          <Icon
            {...className('process-icon')}
            icontype="image"
            src={icon_step1}
          />
          <p>{translate('登录Akulaku')}</p>
        </div>
        <i {...className('dots-icon')}>
          <Icon icontype="image" src={icon_dots_line} />
        </i>
        <div {...className('process-icon-block')}>
          <Icon
            {...className('process-icon')}
            icontype="image"
            src={icon_step2}
          />
          <p>{translate('申请额度')}</p>
        </div>
        <i {...className('dots-icon')}>
          <Icon icontype="image" src={icon_dots_line} />
        </i>
        <div {...className('process-icon-block')}>
          <Icon
            {...className('process-icon')}
            icontype="image"
            src={icon_step3}
          />
          <p>{translate('分期支付')}</p>
        </div>
      </div>
    )
  }
}
