import React, { PureComponent } from 'react'
import { PasswordInput, CodeInput } from '../InputFields'
import { connect } from 'react-redux'
import { Modal } from 'antd-mobile'
import { globalActionCreator } from '@pc/common/globalActions'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../logger.config'
import { Button } from '@pc/components/Button'
import { debounce } from 'lodash'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class SetPasswordForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      password: '',
      confirmPassword: '',
    }
    this.controls = []
    this.saLogger = Logger.getInstance()
  }
  componentDidMount() {
    this.saLogger.onPageClick(loggerConfig.set_password_popup)
  }
  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = (filterObj) => {
    const { showGlobalToast } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg
    const includeList = filterObj.includeList

    result = this.controls.every((elem) => {
      if (includeList && !includeList.includes(elem.name)) return true

      if (elem.validate(elem.state.value) === false) {
        return true
      } else {
        errMsg = elem.validate(elem.state.value)
        showGlobalToast(errMsg)
        return false
      }
    })

    return result
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  //提交设置密码
  submitSetPassword = () => {
    this.saLogger.onPageClick(loggerConfig.confirm)
    this.saLogger.onPageClick(loggerConfig.set_password_click)
    const { password, confirmPassword } = this.state
    const { onSetPassword, setPasswordErrorText, translate } = this.props

    const result = this.beforeSubmit({
      includeList: ['password', 'confirmPassword'],
    })
    if (result === true) {
      if (password !== confirmPassword) {
        setPasswordErrorText(translate('两次输入的密码不同，请重新输入！'))
        return
      }
      onSetPassword(password)
    } else {
      result['confirmPassword'] &&
        setPasswordErrorText(result['confirmPassword'])
    }
  }

  render() {
    const {
      translate,
      showSetPasswordDialog,
      setPasswordErrorText,
      toggleSetPasswordDialog,
      pwdErrorText,
      phoneNumberErrorText,
      type,
      showGlobalToast,
    } = this.props
    const { sent, countDown } = this.state

    return [
      <form key="setPasswordForm">
        <Modal
          visible={showSetPasswordDialog}
          transparent
          maskClosable={false}
          title={translate('设置密码')}
          onClose={() => toggleSetPasswordDialog(false)}
          footer={[
            {
              text: translate('提交'),
              onPress: debounce(this.submitSetPassword, 300),
            },
          ]}
        >
          <div {...className('set-password-form-content')}>
            <PasswordInput
              name="password"
              type="password"
              placeholder={translate('请输入密码（8-16位字符）')}
              onRegister={this.register}
              onControl={this.collect}
              validation={{ type: 'password' }}
              onSuccess={() => {
                // this.handlePasswordInput('password', true)
              }}
              onError={(errMsg) => {
                // this.handlePasswordInput('password', false)
              }}
              onFocus={() => {
                this.saLogger.onPageInput(
                  elementId.REGISTER_PWD_INPUT,
                  'begin',
                  elementId.PAGE_REGISTER,
                )
                this.saLogger.onPageClick(loggerConfig.click_set_password_box)
              }}
              onBlur={() => {
                this.saLogger.onPageInput(
                  elementId.REGISTER_PWD_INPUT,
                  'end',
                  elementId.PAGE_REGISTER,
                )
                this.saLogger.onPageInput(loggerConfig.input_set_password)
              }}
              hasTips
            />
            <PasswordInput
              name="confirmPassword"
              type="password"
              placeholder={translate('请再次输入密码')}
              onRegister={this.register}
              onControl={this.collect}
              validation={{ type: 'password' }}
              errorText={pwdErrorText}
              onSuccess={() => {
                // this.handlePasswordInput('confirmPassword', true)
              }}
              onError={(errMsg) => {
                // this.handlePasswordInput('confirmPassword', false)
              }}
              onFocus={() => {
                setPasswordErrorText('')
                this.saLogger.onPageInput(
                  elementId.REGISTER_CONFIMR_PWD_INPUT,
                  'begin',
                  elementId.PAGE_REGISTER,
                )
                this.saLogger.onPageClick(loggerConfig.click_password_again_box)
              }}
              onBlur={() => {
                this.saLogger.onPageInput(
                  elementId.REGISTER_CONFIMR_PWD_INPUT,
                  'end',
                  elementId.PAGE_REGISTER,
                )
                this.saLogger.onPageInput(loggerConfig.input_password_again)
              }}
            />
          </div>
        </Modal>
      </form>,
    ]
  }
}

const mapStateToProps = (state) => ({
  showSetPasswordDialog: state.getIn(['login', 'showSetPasswordDialog']),
  pwdErrorText: state.getIn(['login', 'pwdErrorText']),
  codeErrorText: state.getIn(['login', 'codeErrorText']),
  phoneNumberErrorText: state.getIn(['login', 'phoneNumberErrorText']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  curPeriod: state.getIn(['login', 'curPeriod']),
})

const mapDispatchToProps = (dispatch) => ({
  onSetPassword(password) {
    dispatch(actionCreator.goSetPassword(password))
  },
  toggleSetPasswordDialog(show) {
    dispatch(actionCreator.goToggleSetPasswordDialog(show))
  },
  sendCodeWithPhoneNumber(phoneNumber) {
    return dispatch(actionCreator.goSendCodeWithPhoneNumber({ phoneNumber }))
  },
  setPasswordErrorText(text) {
    dispatch(actionCreator.goSetPasswordErrorText(text))
  },
  setPhoneNumberErrorText(text) {
    dispatch(actionCreator.goSetPhoneNumberErrorText(text))
  },
  SMSLogin(phoneNumber, captcha) {
    dispatch(actionCreator.goSMSLogin(phoneNumber, captcha))
  },
  showGlobalToast(errMsg) {
    dispatch(globalActionCreator.toggleToast(true, errMsg))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm)
