import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import { push } from 'connected-react-router'
import { getCommonParams } from '@/components/Security/utils'
import { actionCreator } from './store'
import { helpers, withBasic } from '@common'
import { ScrollView } from '@Arui'
import { globalActionCreator } from '@pc/common/globalActions'
import { SecurityVerificationMobile as SecurityVerification } from '@/components/SecurityVerification'
import styles from './style.m.scss'

import Logger from '../../config/logger'
import { Translate } from '../../config/translate'
import LoginForm from './components/LoginForm'
import MegaDiscountList from './components/MegaDiscountList'
import PaymentProcessNew from './components/PaymentProcessNew'
import { ForgetPassword } from '@pc/components/ForgetPassword'
import {
  SCENARIO_NUMBER_MAP,
  PHONE_COUNTRY_CODE_MAP,
  FIRST_PAGE_LOGIN_TYPE,
  FIRST_PAGE_TYPE,
  SECURITY_VERIFICATION_CALL_METHOD,
  SECURITY_VERIFICATION_BIZ_TYPE,
  SLIDER_CONFIG,
  FACE_CONFIG,
} from '@pc/common/macro'

import BizTracker from '@pc/common/tracker/bizTracker'
import loggerConfig from './logger.config'
import { getApiBaseUrl, phoneDesensitization } from '@pc/config/utils'

import OrderInfo from './components/OrderInfo'
import InstallmentDetails from './components/InstallmentDetails'
import LoginTab from './components/LoginTab'
import SMSLoginForm from './components/SMSLoginForm'
import SetPasswordForm from './components/SetPasswordForm'
import { store } from '../../store'
import withPerfCollect from '@/components/withPerfCollect'
import { getSmDeviceData, getDeviceUUID } from '@/components/device-sdk'

const { getRuntimeEnv } = helpers
const className = helpers.classNames.react(styles)

@withBasic
class Login extends PureComponent {
  constructor(props) {
    super(props)

    this.saLogger = Logger.getInstance()
    this.state = {
      deviceUuid: helpers.storage.getSession('deviceData')?.uuid,
    }
  }

  render() {
    const {
      translate,
      cancelPayment,
      installmentList,
      showInstallmentPlan,
      loginType,
      currentPage,
      toggleCurrentPage,
      securityVerificationOnSuccess,
      securityVerificationOnRejected,
      securityVerificationOnClose,
    } = this.props
    const {
      fromLazada,
      languageCode,
      securityVerificationVisible,
      validPhoneNumber,
      defaultPhoneNumber,
      countryId,
    } = this.props

    return (
      <ScrollView {...className('mobile-container')}>
        {!showInstallmentPlan && this.checkBanner() ? (
          <div
            {...className('top_banner')}
            onClick={() => {
              this.saLogger.onPageClick(loggerConfig.click_banner_jump)
              this.saLogger.onPageClick(loggerConfig.click_banner)
            }}
          >
            <a>
              <img src={this.getImg()} alt="" />
            </a>
          </div>
        ) : null}
        {!showInstallmentPlan ? (
          <div {...className('top-bar')}>
            {translate('Akucicil— Virtual Credit Card')}
          </div>
        ) : null}
        {showInstallmentPlan && installmentList.length > 0 ? (
          <OrderInfo />
        ) : null}
        <div className="container">
          {/* 去掉登录注册的按钮 */}
          {currentPage === FIRST_PAGE_TYPE.LOGIN ? (
            <LoginTab
              tabs={[
                {
                  content: translate('短信登录'),
                  position: 'left',
                  key: FIRST_PAGE_LOGIN_TYPE.SMS,
                },
                {
                  content: translate('密码'),
                  position: 'right',
                  key: FIRST_PAGE_LOGIN_TYPE.ACCOUNT,
                },
              ]}
              activeKey={loginType}
              onChoose={this.onTabChoose}
            />
          ) : (
            ''
          )}
          <section
            {...className('content-area login-area', {
              register_area: currentPage === FIRST_PAGE_TYPE.REGISTER,
            })}
          >
            <div {...className('content-body login-content-body clear_fix')}>
              {showInstallmentPlan &&
              currentPage === FIRST_PAGE_TYPE.REGISTER ? (
                <div {...className('register_tab font-roboto-medium')}>
                  {translate('手机号注册')}
                </div>
              ) : null}
              {this.getLoginContent()}
              {showInstallmentPlan ? null : (
                <div {...className('mgt_48')}>
                  <PaymentProcessNew />
                </div>
              )}
              {fromLazada ? (
                <p {...className('cancel-payment')} onClick={cancelPayment}>
                  {translate('取消订单并返回商家')}
                </p>
              ) : null}
              {showInstallmentPlan ? (
                <p
                  onClick={() =>
                    toggleCurrentPage(
                      currentPage === FIRST_PAGE_TYPE.REGISTER
                        ? FIRST_PAGE_TYPE.LOGIN
                        : FIRST_PAGE_TYPE.REGISTER,
                      loginType,
                    )
                  }
                  {...className('register_login_toggle')}
                >
                  {currentPage === FIRST_PAGE_TYPE.REGISTER
                    ? translate('已有账号，去登录')
                    : translate('没有账号，去注册')}
                </p>
              ) : null}
            </div>
          </section>

          {showInstallmentPlan && this.checkBanner() ? (
            <div {...className('activity-image')}>
              <a onClick={this.handleBannerJump}>
                <img src={this.getImg()} alt="" />
              </a>
            </div>
          ) : null}

          <section {...className('content-area discount-area')}>
            <h3 {...className('content-header font-lato-bol')}>
              {translate('特大优惠')}
            </h3>
            <div {...className('content-body')}>
              <MegaDiscountList />
            </div>
          </section>
          <SetPasswordForm />
        </div>
        <InstallmentDetails />
        {securityVerificationVisible ? (
          <SecurityVerification
            visible={securityVerificationVisible}
            locale={languageCode ? languageCode.toLowerCase() : 'in'}
            languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
            env={getRuntimeEnv()}
            deviceId={this.props.device_id}
            deviceUuid={this.state.deviceUuid}
            bizType={
              loginType === FIRST_PAGE_LOGIN_TYPE.ACCOUNT
                ? SECURITY_VERIFICATION_BIZ_TYPE.LOGIN
                : SECURITY_VERIFICATION_BIZ_TYPE.REGISTER
            }
            extraParams={{
              phone: validPhoneNumber || defaultPhoneNumber,
              platformId: helpers.URL.getParam('appId'),
              partnerPhone: validPhoneNumber || defaultPhoneNumber,
              ...getCommonParams(),
            }}
            saParams={{
              pageId: 'openpay01',
              pageName: 'opaypay-login page',
            }}
            countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
            phoneNumber={phoneDesensitization(
              validPhoneNumber || defaultPhoneNumber,
            )}
            onSuccess={securityVerificationOnSuccess}
            onRejected={securityVerificationOnRejected}
            onClose={securityVerificationOnClose}
            // onRequestError={securityVerificationOnClose}
            apiBaseURL={getApiBaseUrl('/capi')}
            getSmDeviceData={getSmDeviceData}
            sliderData={SLIDER_CONFIG}
            faceOptions={FACE_CONFIG}
          />
        ) : null}
      </ScrollView>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showToast } = this.props
    if (nextProps.needLogoutToast) {
      showToast()
    }
  }

  UNSAFE_componentWillMount() {
    const { getBannerConfig, appId, refNo, sign } = this.props
    // 获取banner配置
    getBannerConfig(appId, refNo, sign)

    this.props.dispatch(actionCreator.getOrderInfo())
  }

  componentDidMount() {
    const {
      appId,
      refNo,
      sign,
      getInstallmentInfo,
      defaultPeriod,
      clearOrderDetailData,
      uploadDeviceData,
    } = this.props

    this.getAndReportConfig()
    BizTracker.enter({ sn: 390001 })
    clearOrderDetailData()
    uploadDeviceData()
    // 获取首页的分期新
    getInstallmentInfo(appId, refNo, sign, defaultPeriod)

    // getOrderInfo(appId, refNo, sign, defaultPeriod)
    // console.timeEnd(2)
    /* //携带token代表可能为BL的绑定用户
    token
      ? checkIfFromBL(token, appId, refNo, sign)
      : getOrderInfo(appId, refNo, sign, defaultPeriod) */
    if (!this.state.deviceUuid) {
      getDeviceUUID().then((uuid) => {
        this.setState({ deviceUuid: uuid })
      })
    }
  }

  getAndReportConfig = async () => {
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      partnerUserId: data?.partnerUserId,
      appName: data?.appName,
      Aku_scene: data?.akuScene ? 1 : 2,
    })
  }

  onTabChoose = (key) => {
    this.props.changeLoginType(key)
  }

  // 获取当前页面模块的banner图
  getCurrentBanner = () => {
    const { currentPage, bannerList } = this.props

    const targetBanner =
      bannerList && bannerList.find((item) => item.location === currentPage)

    return targetBanner ? targetBanner : null
  }

  // 判断是否要展示图片
  checkBanner = () => {
    return this.getCurrentBanner()
  }

  // 获取图片
  getImg = () => {
    const currentBanner = this.getCurrentBanner()
    return currentBanner ? currentBanner.imagePhone : null
  }

  // 点击图片跳转
  handleBannerJump = () => {
    const currentBanner = this.getCurrentBanner()
    if (currentBanner && currentBanner.jumpUrl) {
      this.saLogger.onPageClick({
        ...loggerConfig.click_banner,
        Aku_links: currentBanner.jumpUrl,
      })
      this.saLogger.onPageClick({
        ...loggerConfig.click_banner_jump,
        Aku_links: currentBanner.jumpUrl,
      })
      // 使用定时器 是为了让埋点能够正常发出去
      setTimeout(() => {
        window.location.href = currentBanner.jumpUrl
      }, 300)
    }
  }

  // 切换登录类型
  onTabChoose = (key) => {
    // 短信登录切换标签
    BizTracker.click({ cn: key === 1 ? 2 : 8, sn: 390001 })
    this.props.changeLoginType(key)
  }

  /**
   * 灰度发布:
   *  - 未注册用户显示注册内容
   *  - 已注册用户显示原先的内容（默认显示短信登录）
   * 非灰度保持原来的样子
   * **/
  getLoginContent = () => {
    const {
      showInstallmentPlan,
      loginType,
      forgetPassword,
      currentPage,
      handleChangeAgreeBindProtocol,
    } = this.props
    if (showInstallmentPlan && currentPage === FIRST_PAGE_TYPE.REGISTER) {
      return (
        <SMSLoginForm
          onForget={forgetPassword}
          compType={FIRST_PAGE_TYPE.REGISTER}
          handleChangeAgreeBindProtocol={handleChangeAgreeBindProtocol}
        />
      )
    } else {
      return loginType === FIRST_PAGE_LOGIN_TYPE.ACCOUNT ? (
        <LoginForm
          onForget={forgetPassword}
          handleChangeAgreeBindProtocol={handleChangeAgreeBindProtocol}
        />
      ) : (
        <SMSLoginForm
          onForget={forgetPassword}
          handleChangeAgreeBindProtocol={handleChangeAgreeBindProtocol}
        />
      )
    }
  }

  componentWillUnmount() {
    BizTracker.leave()
  }
}

const mapStateToProps = (state) => ({
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
  defaultPeriod: state.getIn(['main', 'defaultPeriod']),
  fromLazada: state.getIn(['main', 'fromLazada']),
  token: state.getIn(['main', 'token']),
  isSignValid: state.getIn(['main', 'isSignValid']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  activity: state.getIn(['login', 'activity']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  loginBanner: state.getIn(['login', 'loginBanner']),
  needLogoutToast: state.getIn(['login', 'needLogoutToast']),
  currentPage: state.getIn(['login', 'currentPage']),
  loginType: state.getIn(['login', 'loginType']),
  bannerList: state.getIn(['login', 'bannerList']),
  installmentList: state.getIn(['login', 'installmentList']),
  showInstallmentPlan: state.getIn(['login', 'showInstallmentPlan']),
  validPhoneNumber: state.getIn(['login', 'validPhoneNumber']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  securityVerificationVisible: state.getIn([
    'login',
    'securityVerificationVisible',
  ]),
  device_id: helpers.storage.get('deviceId') || 'unknown',
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  uploadDeviceData() {
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.LOGIN_PAGE,
      }),
    )
  },
  // getDefaultPhoneNumber() {
  //   dispatch(actionCreator.goGetDefaultPhoneNumber())
  // },
  forgetPassword() {
    const translate = Translate.getInstance().translate
    // dispatch(actionCreator.goSetForgetPasswordSMS())

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('如何找回密码?'),
        content: <ForgetPassword translate={translate} />,
        footer: [],
        hideFooter: true,
      }),
    )
  },
  getInstallmentInfo(appId, refNo, sign, defaultPeriod) {
    dispatch(
      actionCreator.goGetInstallmentInfo(appId, refNo, sign, defaultPeriod),
    )
  },
  // 获取设备信息
  getDeviceData() {
    dispatch(
      globalActionCreator.globalAsyncGetDeviceData({ scenario_number: 1003 }),
    )
  },
  checkIfFromBL(token) {
    dispatch(actionCreator.goCheckIfFromBL(token))
  },
  clearOrderDetailData() {
    dispatch(globalActionCreator.globalClearOrderDetailData())
  },
  cancelPayment() {
    const translate = Translate.getInstance().translate

    Modal.alert('', translate('确定取消支付吗？'), [
      { text: translate('取消'), onPress: () => null },
      {
        text: translate('确认'),
        onPress: () => dispatch(actionCreator.goCancelPayment()),
      },
    ])
  },
  goRegisterPage(curPeriod, callbackPageUrl) {
    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.switch_login_method,
      Aku_channelName: 'sms',
      Aku_buttonName: 'phone number login',
    })

    dispatch(
      push({
        pathname: `./register`,
        state: { curPeriod, callbackPageUrl },
        search: `${window.location.search}`,
      }),
    )
  },
  getBannerConfig(appId, refNo, sign) {
    dispatch(actionCreator.getBannerConfig(appId, refNo, sign))
  },
  showToast() {
    const translate = Translate.getInstance().translate
    dispatch(
      globalActionCreator.toggleToast(
        true,
        translate('您的账号已在新设备登录，如果账号异常，请重新修改密码'),
      ),
    )
    dispatch(actionCreator.goSetNeedLogoutToast(false))
  },
  changeLoginType(loginType) {
    dispatch(actionCreator.goSetLoginType(loginType))
  },
  toggleCurrentPage(currentPage, loginType) {
    // currentPage 将要切换的页面
    const saLogger = Logger.getInstance()
    if (currentPage === FIRST_PAGE_TYPE.LOGIN) {
      saLogger.onPageClick(loggerConfig.click_register_login)
    } else {
      saLogger.onPageClick({
        ...loggerConfig.click_go_to_register,
        Aku_channelName:
          loginType === FIRST_PAGE_LOGIN_TYPE.SMS ? 'sms' : 'account',
      })
    }
    dispatch(actionCreator.goSetCurrentPage(currentPage))
  },
  /**
   * 安全组件校验成功
   * - 登录的话 继续走登录过程
   * - 验证码的话 发起验证码
   * */
  securityVerificationOnSuccess({ operationId }) {
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const securityVerificationCaller = state.getIn([
      'login',
      'securityVerificationCaller',
    ])
    const securityVerificationTempParams = state
      .getIn(['login', 'securityVerificationTempParams'])
      .toJS()

    dispatch(actionCreator.toggleSecurityVerification(false))
    // 如果前置调起方是登录操作，就继续登录操作，如果是获取验证码，那就继续获取验证码 其他情况调起的就是异常
    if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.LOGIN
    ) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      dispatch(
        actionCreator.goLoginWithPwd({
          ...securityVerificationTempParams,
          securityCheck: true,
        }),
      )
    } else if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.CAPTCHA
    ) {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      dispatch(
        actionCreator.goSendCodeWithPhoneNumber({
          ...securityVerificationTempParams,
          securityCheck: true,
        }),
      )
    } else {
      console.log('异常状态')
    }
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件拒绝
  securityVerificationOnRejected(retry) {
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const securityVerificationCaller = state.getIn([
      'login',
      'securityVerificationCaller',
    ])
    // 如果前置调起方是登录
    if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.LOGIN
    ) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 2,
      })
    } else {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 2,
      })
    }
  },
  // 是否同意绑定协议
  handleChangeAgreeBindProtocol(agreeBindProtocol) {
    dispatch(actionCreator.goSetAgreeBindProtocol(agreeBindProtocol))
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)),
)
