import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Switch, Modal } from 'antd-mobile'
import { connect } from 'react-redux'

import { helpers, withBasic } from '@common'
import Icon from '@pc/components/Icon'
import { actionCreator } from '../../store'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../logger.config'

import './style.scss'

const className = helpers.classNames.react({})

@withBasic
class ConnectAccount extends PureComponent {
  state = {
    checked: true,
    showPromptModal: false,
  }

  // 如果组件传了checked值，就把组件变成受控组件
  static getDerivedStateFromProps(props, state) {
    if (props.checked !== undefined && props.checked !== state.checked) {
      return {
        checked: props.checked,
      }
    }
    return null
  }

  saLogger = Logger.getInstance()

  handleShowPromptModal = () => {
    this.setState({
      showPromptModal: !this.state.showPromptModal,
    })
  }

  handelSwitchState = () => {
    const targetChecked = !this.state.checked
    this.setState(
      {
        checked: targetChecked,
      },
      () => {
        // 对外抛出值
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(targetChecked)
        }
        if (targetChecked) {
          this.handelSaLogger('click_binding_open')
        } else {
          this.handelSaLogger('click_binding_close')
        }
      },
    )
  }

  handelSaLogger = (key) => {
    const { channelType } = this.props
    this.saLogger.onPageClick({
      ...loggerConfig[key],
      Aku_channelName: channelType,
    })
  }

  render() {
    const { translate } = this.props
    return (
      <div {...className('connect_account_block')}>
        <p>
          {translate('讲你的账户连接到tokopedia，以加快付款速度')}
          <Icon
            icontype="svg"
            name="remind"
            {...className('icon_reminder')}
            onClick={() => {
              this.handelSaLogger('click_binding_detail')
              this.handelSaLogger('click_binding_modal_view')
              this.handleShowPromptModal()
            }}
          />
        </p>
        <Switch
          {...className('btn_switch')}
          color={'#e62117'}
          checked={this.state.checked}
          onChange={this.handelSwitchState}
        />

        <Modal
          transparent
          closable={true}
          maskClosable={false}
          title={translate('账户关联')}
          visible={this.state.showPromptModal}
          onClose={() => {
            this.handelSaLogger('click_binding_modal_close')
            this.handleShowPromptModal()
          }}
          footer={[
            {
              text: translate('我知道了'),
              onPress: () => {
                this.handelSaLogger('click_binding_modal_ok')
                this.handleShowPromptModal()
              },
            },
          ]}
        >
          <p {...className('modal_content')}>
            {translate(
              '将您的Akuaku账号与Tokopedia账号关联，可快速完成付款，无需登录，无需输入手机号，OTP，以及密码；',
            )}
          </p>
        </Modal>
      </div>
    )
  }
}

function HOCConnectAccount(props) {
  if (props.isShow) {
    return <ConnectAccount {...props} />
  }
  return null
}

export default React.memo(HOCConnectAccount)
