import React, { PureComponent, Fragment } from 'react'

import { helpers } from '@common'

import leftIncline from '@pc/assets/img/left@3x.png'
import rightIncline from '@pc/assets/img/right@3x.png'
import styles from './style.m.scss'
import loggerConfig from '../../logger.config'
import Logger, * as elementId from '@pc/config/logger'
import { FIRST_PAGE_LOGIN_TYPE } from '@pc/common/macro'

const className = helpers.classNames.react(styles)

export default class LoginTab extends PureComponent {
  state = {
    current: null,
  }

  onItemClick = (key, index) => {
    this.setState({ current: key, position: index === 0 ? 'left' : 'right' })
    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.switch_login_method,
      Aku_channelName: key === FIRST_PAGE_LOGIN_TYPE.SMS ? 'sms' : 'account',
    })

    this.props.onChoose && this.props.onChoose(key)
  }

  render() {
    const { tabs, activeKey } = this.props
    const { current } = this.state

    return (
      <div {...className('login-tab-wrapper')}>
        {tabs.map((tab, index) => {
          let isActive = activeKey === tab.key
          let position = tab.position

          return (
            <Fragment key={tab.key}>
              {isActive && (
                <div
                  key={`${tab.key}_active`}
                  {...className('active-item-wrapper', { [position]: true })}
                >
                  <div {...className('active-item font-roboto-medium')}>
                    {position === 'left' && (
                      <img {...className('left-incline')} src={leftIncline} />
                    )}
                    {position === 'right' && (
                      <img {...className('right-incline')} src={rightIncline} />
                    )}
                    <span>{tab.content}</span>
                  </div>
                </div>
              )}
              <div
                key={tab.key}
                onClick={() => this.onItemClick(tab.key, index)}
                {...className('tab-item font-roboto-medium', {
                  isActive: isActive,
                })}
              >
                {tab.content}
              </div>
            </Fragment>
          )
        })}
      </div>
    )
  }
}
