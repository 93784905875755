import React, { PureComponent } from 'react'
import { PhoneInput, CodeInput } from '../InputFields'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import BizTracker from '@pc/common/tracker/bizTracker'
import Icon from '@pc/components/Icon'

import { globalActionCreator } from '@pc/common/globalActions'
import { actionCreator } from '../../store'
import { helpers, withBasic, asyncComponent } from '@common'
import Logger, * as elementId from '@pc/config/logger'
import { Button } from '@pc/components/Button'
import loggerConfig from '../../logger.config'

import { FIRST_PAGE_TYPE } from '@pc/common/macro'
const GraphicCodeForm = asyncComponent(() =>
  import('../../../register/components/GraphicCodeForm'),
)

import ConnectAccount from '../ConnectAccount'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class SMSLoginForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      phoneNumber: '',
      password: '',
      sent: false,
      countDown: 60,
      agreementSelected: true,
    }
    this.controls = []
    this.saLogger = Logger.getInstance()
  }

  //
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sendSMSCode && !this.props.sendSMSCode) {
      this.props.setSendSMSCode(false)
      this.startCountDown()
    }
  }

  // 切换tab的适合用来同步用户填写的手机号 手机号登录和密码登录
  componentWillUnmount() {
    const { phoneNumber } = this.state
    const { setDefaultPhoneNumber, compType } = this.props
    // 注册页面的这个不需要执行保存
    compType !== FIRST_PAGE_TYPE.REGISTER &&
      phoneNumber &&
      setDefaultPhoneNumber(phoneNumber)
  }

  startCountDown = (cd = 60) => {
    this.setState({ sent: true })
    cd--
    if (cd < 0) {
      this.setState({ sent: false, countDown: 60 })
      return
    }
    setTimeout(() => {
      this.setState({ countDown: cd })
      this.startCountDown(cd)
    }, 1000)
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = (filterObj) => {
    const { showGlobalToast } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg
    const includeList = filterObj.includeList

    result = this.controls.every((elem) => {
      if (includeList && !includeList.includes(elem.name)) return true

      if (elem.name === 'password' || elem.name === 'graphicCaptcha')
        return true //不校验密码和图形验证码

      let value =
        elem.state.value === null ? elem.props.defaultValue : elem.state.value
      if (elem.validate(value) === false) {
        return true
      } else {
        errMsg = elem.validate(value)
        showGlobalToast(errMsg)
        return false
      }
    })

    return result
  }

  submitPassword = () => {
    if (this.props.compType !== FIRST_PAGE_TYPE.REGISTER) {
      // 登录的埋点事件
      BizTracker.click({
        cn: 13,
        sn: 390001,
      })
    }
    const {
      SMSLogin,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      defaultPhoneNumber,
      showGlobalToast,
      translate,
      setCodeErrorText,
      compType,
    } = this.props
    const { captcha, phoneNumber, agreementSelected } = this.state

    if (!agreementSelected) {
      showGlobalToast(translate('请勾选条款协议'))
      return
    }

    const result = this.controls.every((elem) => {
      const errMsg = elem.validate(
        elem.state.value === null ? elem.props.defaultValue : elem.state.value,
      )

      if (errMsg === false) {
        return true
      } else {
        if (elem.name === 'password') {
          setPasswordErrorText(errMsg)
        } else if (elem.name === 'captcha') {
          setCodeErrorText(errMsg)
        } else {
          setPhoneNumberErrorText(errMsg)
        }
        return false
      }
    })

    if (result) {
      SMSLogin(phoneNumber ? phoneNumber : defaultPhoneNumber, captcha)
      const loggerInfo =
        compType === 'register'
          ? loggerConfig.click_register_register
          : { ...loggerConfig.login, Aku_channelName: 'sms' }
      this.saLogger.onPageClick(loggerInfo)
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  sendCode = async () => {
    // 点击发送验证码
    BizTracker.click({ cn: 4, sn: 390001 })
    const { sendCodeWithPhoneNumber, defaultPhoneNumber, compType } = this.props
    const { phoneNumber } = this.state

    const result = this.beforeSubmit({ includeList: ['phoneNumber'] })
    const saLogger = Logger.getInstance()
    let loggerInfo =
      compType === FIRST_PAGE_TYPE.REGISTER
        ? loggerConfig.click_register_send
        : { ...loggerConfig.send, Aku_channelName: 'sms' }
    saLogger.onPageClick(loggerInfo)

    if (result === true) {
      sendCodeWithPhoneNumber(phoneNumber ? phoneNumber : defaultPhoneNumber)
    }
  }

  // 图形验证码结果通知
  graphicCodeFormCb = async (data = {}) => {
    if (data.success) {
      this.startCountDown()
    }
  }

  clickTerm = () => {
    BizTracker.click({ cn: 7, sn: 390001 })
    const { openAgreement, countryCode, languageCode } = this.props

    openAgreement(countryCode, languageCode)
    this.saLogger.onPageClick({
      ...loggerConfig.click_agreement,
      Aku_channelName: 'sms',
    })
  }

  clickPolicy = () => {
    const env = helpers.getRuntimeEnv()
    location.href = `https://${
      env === 'prod' ? '' : 'test-'
    }ec-mall.akulaku.com/ec-basic/authorize/policy`
  }

  onSelectedChange = () => {
    BizTracker.click({ cn: 6, sn: 390001 })
    this.setState({
      agreementSelected: !this.state.agreementSelected,
    })
  }

  render() {
    const {
      translate,
      countryCode,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      codeErrorText,
      phoneNumberErrorText,
      type,
      defaultPhoneNumber,
      onForget,
      setCodeErrorText,
      compType, // 组件类型 register 注册
      showBindProtocol,
      agreeBindProtocol,
      handleChangeAgreeBindProtocol,
      canModifyPhoneNumber,
      showPostTokenProtocol,
      setValidPhoneNumber,
    } = this.props
    const { sent, countDown, agreementSelected, phoneNumber } = this.state

    return [
      <form
        key="loginForm"
        {...className('form-sms-login', {
          'form-sms-login-pc': type === 'pc',
        })}
      >
        {type !== 'pc' ? null : (
          <p {...className('form-title font-lato-reg')}>
            {translate('手机号')}
          </p>
        )}
        <PhoneInput
          name="phoneNumber"
          type="text"
          onChange={(value) => {
            // 验证码手机输入框
            BizTracker.input({
              key: 'sms-login-phoneinput',
              cn: 3,
              ic: value,
              sn: 390001,
            })
            setValidPhoneNumber(value)
          }}
          defaultValue={defaultPhoneNumber}
          placeholder={translate('手机号')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'phone' }}
          onSuccess={() => {
            setPhoneNumberErrorText('')
          }}
          onError={(errMsg) => {
            setPhoneNumberErrorText(errMsg)
          }}
          onFocus={() => {
            setPhoneNumberErrorText('')
            this.saLogger.onPageInput({
              ...loggerConfig.click_phone_num,
              Aku_channelName: 'sms',
            })
          }}
          onBlur={(e, value) => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_phone_num,
              Aku_channelName: 'sms',
            })
            setValidPhoneNumber(value)
          }}
          errorText={phoneNumberErrorText}
          countryCode={countryCode}
          required
          platform={type === 'pc' ? 'pc' : 'mobile'}
          hasClearIcon
          disabled={
            canModifyPhoneNumber !== undefined && canModifyPhoneNumber !== null
              ? !canModifyPhoneNumber
              : false
          }
        />

        <CodeInput
          name="captcha"
          type="text"
          placeholder={translate('验证码')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'code' }}
          onSend={() => this.sendCode()}
          btnDisable={!sent ? false : true}
          btnText={sent ? `${countDown}s` : translate('发送')}
          btnDisable={sent}
          errText={codeErrorText}
          onFocus={() => {
            setCodeErrorText('')
            this.saLogger.onPageInput({
              ...loggerConfig.click_password_box,
              Aku_channelName: 'sms',
            })
          }}
          onChange={(value) => {
            // 验证码输入框
            BizTracker.input({
              key: 'sms-login-captchainput',
              cn: 5,
              ic: value,
              sn: 390001,
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_password,
              Aku_channelName: 'sms',
            })
          }}
          onSuccess={() => {
            setCodeErrorText('')
            // this.handleCodeInput(true)
          }}
          onError={(errMsg) => {
            setCodeErrorText(errMsg)
            // this.handleCodeInput(false)
          }}
          hasClearIcon
        />
        {/* 绑定账号 toko需求因第三方原因暂时无法进行，故关闭绑定账号功能 */}
        {/* <ConnectAccount
          channelType={'account'}
          isShow={showBindProtocol}
          translate={translate}
          checked={agreeBindProtocol}
          onChange={handleChangeAgreeBindProtocol}
        /> */}

        <Button
          text={
            compType === FIRST_PAGE_TYPE.REGISTER
              ? translate('注册')
              : translate('登录')
          }
          disabled={false /* !codeStatus || !phoneStatus */}
          onClick={this.submitPassword}
          platform={type === 'pc' ? 'pc' : 'mobile'}
          {...className('', {
            'btn-login-pc': type === 'pc',
            'btn-login': type !== 'pc',
          })}
        />
        <div {...className('terms')}>
          <div onClick={this.onSelectedChange}>
            {agreementSelected ? (
              <Icon
                {...className('icon-selected')}
                icontype="svg"
                name="success"
              />
            ) : (
              <i {...className('icon-circle')} />
            )}
          </div>
          <p>
            <span onClick={this.onSelectedChange}>
              {translate('点击注册代表您已阅读并同意以下')}
            </span>
            <a onClick={this.clickTerm}>{translate('用户注册')}</a>
            {showPostTokenProtocol ? (
              <React.Fragment>
                &nbsp;{translate('和')}&nbsp;
                <a onClick={this.clickPolicy}>{translate('使用条款')}</a>
              </React.Fragment>
            ) : null}
          </p>
        </div>
        <GraphicCodeForm
          graphicCodeFormCb={this.graphicCodeFormCb}
          phoneNumber={this.state.phoneNumber || defaultPhoneNumber}
        />
      </form>,
    ]
  }
}

const mapStateToProps = (state) => ({
  sent: state.getIn(['login', 'sent']),
  countDown: state.getIn(['login', 'countDown']),
  codeErrorText: state.getIn(['login', 'codeErrorText']),
  phoneNumberErrorText: state.getIn(['login', 'phoneNumberErrorText']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  sendSMSCode: state.getIn(['login', 'sendSMSCode']),
  showBindProtocol: state.getIn(['login', 'showBindProtocol']),
  agreeBindProtocol: state.getIn(['login', 'agreeBindProtocol']),
  canModifyPhoneNumber: state.getIn(['main', 'canModifyPhoneNumber']),
  showPostTokenProtocol: state.getIn(['login', 'showPostTokenProtocol']),
})

const mapDispatchToProps = (dispatch) => ({
  setValidPhoneNumber(validPhoneNumber) {
    dispatch(actionCreator.goSetCurValidPhoneNumber(validPhoneNumber))
  },
  openAgreement(countryCode, languageCode) {
    dispatch(
      push({
        pathname: './terms/serviceAgreement',
        search: window.location.search,
      }),
    )

    // languageCode === 'EN'
    //   ? (window.location.href = `/v2/terms.html?lang=ph`)
    //   : (window.location.href = `/v2/terms.html?lang=${countryCode.toLowerCase()}`)
  },
  sendCodeWithPhoneNumber(phoneNumber) {
    return dispatch(actionCreator.goSendCodeWithPhoneNumber({ phoneNumber }))
  },
  setPasswordErrorText(text) {
    dispatch(actionCreator.goSetPasswordErrorText(text))
  },
  setPhoneNumberErrorText(text) {
    dispatch(actionCreator.goSetPhoneNumberErrorText(text))
  },
  setCodeErrorText(text) {
    dispatch(actionCreator.goSetCodeErrorText(text))
  },
  SMSLogin(phoneNumber, captcha) {
    dispatch(actionCreator.goSMSLogin(phoneNumber, captcha))
  },
  showGlobalToast(errMsg) {
    dispatch(globalActionCreator.toggleToast(true, errMsg))
  },
  setDefaultPhoneNumber(phoneNumber) {
    dispatch(actionCreator.goSetDefaultPhoneNumber(phoneNumber))
  },
  setSendSMSCode(sendSMSCode) {
    dispatch(actionCreator.setSendSMSCode(sendSMSCode))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SMSLoginForm)
