import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd-mobile'
import { ModalTitlePop } from '@pc/components/ModalTitle'
import Icon from '@pc/components/Icon'

import BizTracker from '@pc/common/tracker/bizTracker'
import { helpers, withBasic } from '@common'
import { actionCreator } from '../../store'
import Logger, * as elementId from '@pc/config/logger'

import styles from './style.m.scss'
import loggerConfig from '../../logger.config'

const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class InstallmentDetails extends PureComponent {
  renderListItem = (item) => {
    const {
      translate,
      chooseCurSelected,
      currencyCode,
      formatPrice,
      curPeriod,
    } = this.props
    const checked = curPeriod && Number(curPeriod) === Number(item.periods)

    return (
      <li
        onClick={() => chooseCurSelected(item.periods)}
        {...className('content_list_item', { checked: checked })}
        key={item.periods}
      >
        <div {...className('item_left')}>
          <div {...className('item_left_installment')}>
            {translate('{currencyCode} {price} * {num}期', {
              currencyCode: currencyCode,
              price: formatPrice(item.monthlyInstallmentAmount || ''),
              num: item.periods || '',
            })}
          </div>
          <div {...className('item_left_fee')}>
            {translate('每期利率{fee}%', {
              fee: (item.monthlyInterestRate * 100).toFixed(2) || '',
            })}
          </div>
        </div>
        {checked ? (
          <Icon
            name="registered_ico_choose_noselected3x"
            icontype="svg"
            {...className('item_right_icon')}
          />
        ) : (
          <Icon
            name="iconNocheck"
            icontype="svg"
            {...className('item_right_icon')}
          />
        )}
      </li>
    )
  }

  render() {
    const { translate, visible, closeModal, installmentList = [] } = this.props
    return (
      <Modal
        {...className('installment_details_container font_lato_medium')}
        popup
        visible={visible}
        animationType="slide-up"
        key="installment_detail"
        onClose={closeModal}
        title={
          <ModalTitlePop
            title={translate('选择分期期数')}
            onClick={closeModal}
            borderBottom={false}
          />
        }
      >
        <div {...className('installment_details_content')}>
          <div {...className('content_tips')}>
            *
            {translate(
              '以下可分期金额、分期期数和利率仅供参考，最终以登录账户后实际展示的分期信息为准',
            )}
          </div>
          <ul {...className('content_list')}>
            {installmentList &&
              installmentList.map((item) => this.renderListItem(item))}
          </ul>
        </div>
      </Modal>
    )
  }

  // 弹窗显示埋点
  logShowModal = () => {
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.installment_popup)
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.logShowModal()
    }
  }
}

const mapStateToProps = (state) => ({
  visible: state.getIn(['login', 'installmentVisible']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  installmentList: state.getIn(['login', 'installmentList']),
})

const mapDispatchToProps = (dispatch) => ({
  closeModal() {
    // 关闭分期方案
    BizTracker.click({
      cn: 15,
      sn: 390001,
    })
    dispatch(actionCreator.setInstallmentVisible(false))
  },
  chooseCurSelected(period) {
    // 选择分期方案
    BizTracker.click({
      cn: 16,
      sn: 390001,
    })
    dispatch(actionCreator.goChooseCurPeriod(period))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentDetails)
